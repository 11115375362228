import React, { useState } from 'react'
import Pdf from '../../../../../../Components/Icons/Pdf'
import NewReportTable from '../../../../../../Components/Others/NewReportTable'
import { CancelButton, SubmitButton } from '../../../../../../Components/Utils/Buttons'
import { useFormik } from 'formik'
import UseFetchReportData from '../../../../../../Components/Hooks/useFetchReportData'
import axiosInstance from '../../../../../../Services/axiosCommon'
import { FormikSelectTwoSmall, TwoNEWSmall } from '../../../../../../Components/Utils/Select'
import {  NuDateSmall } from '../../../../../../Components/Utils/Input'
import UseDropDown from '../../../../../../Components/Hooks/useDropDown'
import NuDateFormat from '../../../../../../Components/Utils/NuDate'
import useFetchMataData from '../../../../../../Components/Hooks/useFetchMataData'
import Xls from '../../../../../../Components/Icons/Xls'
import UseSupplierDropDownNew from '../../../../../../Components/Hooks/useSupplierSearch'
import NuAmount from '../../../../../../Components/Utils/NuAmount'

function PurchaseOrder() {
   const [search, setSearch] = useState("");
   const { data: vendors, setDataSupplier } = UseSupplierDropDownNew(
    "/vendors/drop-down",
    search
  );
  
    const [showFilter,setShowFilter] = useState(false)
    const { data: RFQAssignee } = UseDropDown("/user/procurement/drop-down");
    const { value } = useFetchMataData(
      `/dropdown/dropdown-details/`,
      "paymentStatus_po"
    );
    const [filter, setFilter] = useState(false);
    const headers = [
        {
            title: "YPR",
            width: "14.28%",
            field: "prRef",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "PO #",
            width: "14.28%",
            field: "poRef",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Date",
            width: "14.28%",
            field: "date",
            type:"date",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Supplier",
            width: "14.28%",
            field: "vendor",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Amount",
            width: "14.28%",
            field: "amount",
            textAlign: "left",
            type:"amount",
            rowAlign: "left",
          },
          {
            title: "Payment Status",
            width: "14.28%",
            field: "paymentStatus",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Purchaser",
            width: "14.28%",
            field: "purchaser",
            textAlign: "left",
            rowAlign: "left",
          },
    ];

        const [filterProps, setFilterProps] = useState({
           purchaser:"",
            dateFrom:"",
            dateTo:"",
            paymentStatus:"",
            vendorId:"",

        });

        const [params, setParams] = useState({
            purchaser:"",
            dateFrom:"",
            dateTo:"",  
            paymentStatus:"",
            vendorId:"",
        });

       const formik = useFormik({
         enableReinitialize: true,
         initialValues: filterProps,
         validateOnChange: false,
         validateOnBlur: false,
         onSubmit: async (value) => {
           setParams({
            purchaser:value.purchaser,
            dateFrom:value.dateFrom,
            dateTo:value.dateTo,
            paymentStatus:value.paymentStatus,
            vendorId:value?.vendorId,
           })
           setTimeout(() => {
            setFilter(true)
            // closeFilterDialog();
           }, 1000);
         }
       }) 
       const {data,getData,dataLoading,total,other} = UseFetchReportData("/report/po",params)


       function closeFilterDialog(){
         setShowFilter(false)
         formik.resetForm()
         setFilter(false)
         setParams({
          purchaser:"",
          dateFrom:"",
          dateTo:"",
         })
       }





       const downloadPDF = async () => {
        try {
            const response = await axiosInstance.get(`/report/po/pdf`,{params}, {
                responseType: 'blob' // Important to specify the response type as blob
            });
    
            // Create a Blob from the response
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdfBlob);
            link.download = `Purchase Order Report ${NuDateFormat({value:new Date(),format:"DD-MM-YYYY"})}.pdf`; // The filename you want for the downloaded file
    
            // Append the link to the body (required for Firefox)
            document.body.appendChild(link);
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
    
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };
    const downloadxlsx = async () => {
      try {
          const response = await axiosInstance.get(`/report/po/xlsx`, {
              params, 
              responseType: 'blob' // Important to specify the response type as blob
          });
  
          // Create a Blob from the response
          const xlsxBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(xlsxBlob);
          link.download = `Purchase Order Report ${NuDateFormat({value: new Date(), format: "DD-MM-YYYY"})}.xlsx`;
  
          // Append the link to the body (required for Firefox)
          document.body.appendChild(link);
  
          // Programmatically click the link to trigger the download
          link.click();
  
          // Clean up and remove the link
          document.body.removeChild(link);
  
      } catch (error) {
          console.error('Error downloading the xlsx:', error);
      }
  };
  return (
    <>
      <div className="w-full h-full">
        <div className="w-full  items-center gap-x-3 bg-white">

          <div className='flex justify-between items-center w-full'>
          <p className=" font-semibold text-xs 2xl:text-sm  pt-1">
           Purchase Order : {total||0}
          </p>

          <p className=" font-semibold text-[10px] 2xl:text-xs  pt-1">
          Total Amount : <NuAmount value={other["totalAmount"]||0}/>
          </p>

          <div className=" flex gap-4 justify-end mt-1">
          <button onClick={downloadPDF}>
              <Pdf />
            </button>
            <button onClick={downloadxlsx}>
              <Xls />
            </button>
            </div>
          </div>
        
          <form onSubmit={formik.handleSubmit}>
            <div className=' w-full grid grid-cols-3 gap-x-3'>
            <NuDateSmall
                label="From Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="dateFrom"
                width="w-full"
              />
            <NuDateSmall
                label="To Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="dateTo"
                width="w-full"
              />
              <TwoNEWSmall
                label="Supplier"
                placeholder="Choose"
                Options={vendors}
                formik={formik}
                name="vendorId"
                setSearch={setSearch}
                FixPosition={false}
                PortalTarget={false}
                width="w-full"
              />
              <FormikSelectTwoSmall
                type="text"
                label="Payment Status"
                placeholder="Choose"
                Options={value}
                formik={formik}
                name="paymentStatus"
                width="w-full"
                FixPosition={true}
                PortalTarget={false}
                isClear={true}
              />
              <FormikSelectTwoSmall
                type="text"
                label="Purchaser"
                placeholder="Choose"
                Options={RFQAssignee}
                formik={formik}
                name="purchaser"
                width="w-full"
                FixPosition={true}
                PortalTarget={false}
                isClear={true}
              />
              <div className=" w-full flex justify-end mb-1.5">
                <div className=" w-full flex justify-end items-end gap-2">
                  <SubmitButton name="Apply" />
                  <CancelButton label='Clear'  handleClick={closeFilterDialog} />
                </div>
              </div>
            </div>
            </form>
            </div>
        
        <NewReportTable headers={headers} data={data} dataLoading={dataLoading} />
      </div>
    </>
  );
}

export default PurchaseOrder;