import React, { useState } from 'react'
import Pdf from '../../../../../../Components/Icons/Pdf'
import Filter from '../../../../../../Components/Icons/Filter'
import NewReportTable from '../../../../../../Components/Others/NewReportTable'
import CloseIcon from '../../../../../../Components/Icons/Close'
import { CancelButton, SubmitButton } from '../../../../../../Components/Utils/Buttons'
import { useFormik } from 'formik'
import UseFetchReportData from '../../../../../../Components/Hooks/useFetchReportData'
import axiosInstance from '../../../../../../Services/axiosCommon'

import FormikSelectTwo, { FormikSelectTwoSmall } from '../../../../../../Components/Utils/Select'
import { NuDate, NuDateSmall } from '../../../../../../Components/Utils/Input'
import UseDropDown from '../../../../../../Components/Hooks/useDropDown'
import NuDateFormat from '../../../../../../Components/Utils/NuDate'
import Xls from '../../../../../../Components/Icons/Xls'
import NuAmount from '../../../../../../Components/Utils/NuAmount'

function CostSaving() {
    const [showFilter,setShowFilter] = useState(false)
    const { data: RFQAssignee } = UseDropDown("/user/procurement/drop-down");
    const [filter, setFilter] = useState(false);
    const headers = [
        {
            title: "Name",
            width: "40%",
            field: "name",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Orders",
            width: "15%",
            field: "count",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Supplier Quote Value",
            width: "15%",
            field: "value",
            textAlign: "left",
            rowAlign: "left",
            type:"amount"
          },
          {
            title: "Negotiated Value",
            width: "15%",
            field: "ordered_price",
            textAlign: "left",
            rowAlign: "left",
            type:"amount"
          },
          {
            title: "Cost Saving",
            width: "15%",
            field: "difference",
            textAlign: "left",
            rowAlign: "left",
            type:"amount"
          },
    ];

        const [filterProps, setFilterProps] = useState({
          purchaser:"",
            dateFrom:"",
            dateTo:"",
        });

        const [params, setParams] = useState({
            purchaser:"",
            dateFrom:"",
            dateTo:"",  
        });

       const formik = useFormik({
         enableReinitialize: true,
         initialValues: filterProps,
         validateOnChange: false,
         validateOnBlur: false,
         onSubmit: async (value) => {
           setParams({
            purchaser:value.purchaser,
            dateFrom:value.dateFrom,
            dateTo:value.dateTo,
           })
           setTimeout(() => {
            setFilter(true)
            // closeFilterDialog();
           }, 1000);
         }
       }) 
       const {data,getData,dataLoading,total,other} = UseFetchReportData("/report/cost-save-by-procurement",params)


       function closeFilterDialog(){
         setShowFilter(false)
         formik.resetForm()
         setFilter(false)
         setParams({
          purchaser:"",
          dateFrom:"",
          dateTo:"",
         })
       }





       const downloadPDF = async () => {
        try {
            const response = await axiosInstance.get(`/report/cost-save-by-procurement/pdf`,{params}, {
                responseType: 'blob' // Important to specify the response type as blob
            });
    
            // Create a Blob from the response
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdfBlob);
            link.download = `Cost Saving Analysis Report ${NuDateFormat({value:new Date(),format:"DD-MM-YYYY"})}.pdf`; // The filename you want for the downloaded file
    
            // Append the link to the body (required for Firefox)
            document.body.appendChild(link);
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
    
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };
    const downloadxlsx = async () => {
      try {
          const response = await axiosInstance.get(`/report/cost-save-by-procurement/xlsx`, {
              params, 
              responseType: 'blob' // Important to specify the response type as blob
          });
  
          // Create a Blob from the response
          const xlsxBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(xlsxBlob);
          link.download = `Cost Saving Analysis Report ${NuDateFormat({value: new Date(), format: "DD-MM-YYYY"})}.xlsx`;
  
          // Append the link to the body (required for Firefox)
          document.body.appendChild(link);
  
          // Programmatically click the link to trigger the download
          link.click();
  
          // Clean up and remove the link
          document.body.removeChild(link);
  
      } catch (error) {
          console.error('Error downloading the xlsx:', error);
      }
  };
  
  return (
    <>
      <div className="w-full h-full">
        <div className="w-full h-24  items-center gap-x-3">

        <div className='flex gap-x-2 justify-between items-center w-full'>
          <p className=" font-semibold text-xs 2xl:text-sm  pt-1">
           Cost Saving Analysis : {total||0}
          </p>
          
          <p className=" font-semibold text-[10px] 2xl:text-xs  pt-1">
            Order : {other["totalCount"]||0}
          </p>

          <p className=" font-semibold text-[10px] 2xl:text-xs  pt-1">
            Value : <NuAmount value={other["totalValue"]||0}/>
          </p>

          <p className=" font-semibold text-[10px] 2xl:text-xs  pt-1">
            Negotiated  : <NuAmount value={other["totalOrderPrice"]||0}/>
          </p>

          <p className=" font-semibold text-[10px] 2xl:text-xs  pt-1">
            Cost Saving  : <NuAmount value={other["totalDifference"]||0}/>
          </p>

          <div className=" flex gap-4 justify-end mt-1">
          <button onClick={downloadPDF}>
              <Pdf />
            </button>
            <button onClick={downloadxlsx}>
              <Xls />
            </button>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className=' w-full grid grid-cols-4 gap-x-3'>
            <NuDateSmall
                label="From Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="dateFrom"
                width="w-full"
              />
            <NuDateSmall
                label="To Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="dateTo"
                width="w-full"
              />
              <FormikSelectTwoSmall
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={RFQAssignee}
                formik={formik}
                name="purchaser"
                width="w-full"
                FixPosition={true}
                PortalTarget={false}
                isClear={true}
              />
              <div className=" w-full flex justify-end mb-1.5">
                <div className=" w-full flex justify-end items-end gap-2">
                  <SubmitButton name="Apply" />
                  <CancelButton label='Clear'  handleClick={closeFilterDialog} />
                </div>
              </div>
            </div>
            </form>

        </div>
        <NewReportTable headers={headers} data={data} dataLoading={dataLoading} />
      </div>
    </>
  );
}

export default CostSaving