import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../Utils/Pagenation";
import { TextMediumSM } from "../Text/MediumText";
import ModalContainer from "./ModalContainer";
import MoreOptions from "../Utils/MoreOptions";
import NuDate from "../Utils/NuDate";
import NuAmount from "../Utils/NuAmount";
import { Link } from "react-router-dom";
import FileIcon from "../Icons/FileIcon";
import { GetAccess, GetRole } from "../Utils/roles";
import Date from "../Icons/Date";
import { CancelButton } from "../Utils/Buttons";
import moment from "moment";

// Table component

const DynamicTable = ({
  fetcher,
  data = [],
  headers,
  bodyHeight,
  total,
  hasMultiEdit,
  hasMultiDelete,
  listofNumbers,
  selectOne = () => {},
  selectMultiple = () => {},
  fullList,
  getById,
  DeteleAccess,
  EditAccess,
  dataLoading,
  pageIndex,
  searchQuery,
  dateShow,
}) => {
  const { t, i18n } = useTranslation();

  const RenderCell = ({ header, row }) => {
    if (header && row) {
      if (header?.field === "prDesc") {
        return (
          <p className="line-clamp-2 text-[11px] text-gray-700 text-center">
            {row.prDesc || "No Description"}
          </p>
        );
      }
      if (header?.hasTwoFields) {
        return (
          <div className="text-center">
            <p className="text-[12px] text-[#606279] font-medium">
              {row[header?.fieldOne] || "N/A"}
            </p>

            <p
              className={`text-[10px] font-medium italic ${
                row[header?.fieldTwo] === "Public"
                  ? "text-green-600"
                  : "text-purple-600"
              }`}
            >
              {row[header?.fieldTwo] || "N/A"}
            </p>
          </div>
        );
      }

      if (header?.field === "vendorNameOrPurchaserName") {
        const vendorName = row.vendorName;
        const purchaserName = row.purchaserName;

        if (vendorName) {
          return (
            <p className="text-[11px] text-red-700 text-center">{vendorName}</p>
          );
        } else if (purchaserName) {
          return (
            <p className="text-[11px] text-green-700 text-center">
              {purchaserName}
            </p>
          );
        } else {
          return (
            <p className="text-[11px] text-gray-400 text-center">No Data</p>
          );
        }
      }
      if (header?.isLink) {
        return (
          <Link
            to={header.link + row[header.id]}
            state={{
              data: row,
              pageIndex: pageIndex,
              searchQuery: searchQuery,
            }}
          >
            {getField(row, header?.field, header?.type)}
          </Link>
        );
      } else {
        if (header?.hasTwo === true) {
          return (
            <div>
              <p className=" text-[11px] text-green-400">
                <NuAmount value={row[header?.fieldOne] || 0} /> {` (P)`}
              </p>
              <p className=" text-[10px] text-gray-400">
                <NuAmount value={row[header?.fieldTwo] || 0} /> {` (E)`}
              </p>
            </div>
          );
        } else if (
          header?.field === "action" &&
          header?.title === "Action" &&
          header?.contents
        ) {
          return (
            <MoreOptions
              data={row}
              fetcher={fetcher}
              id={row[header.contents?.id] || ""}
              removevalue={row[header.contents?.removeValue] || "undefined"}
              url={header.contents?.url}
              moduleName={header.contents?.moduleName}
              from={header.contents?.from}
              editLink={header.contents?.editLink}
              viewLink={header.contents?.viewLink}
              curentPage={pageIndex}
              DeteleAccess={DeteleAccess}
              EditAccess={EditAccess}
              EmailRequired={header.contents?.EmailRequired}
              isEmailSent={row.isEmailSent}
              emailEnable={row.emailEnable}
              emailType={header.contents?.emailType}
              adminPermmision={header.contents?.adminPermmision}
              searchQuery={searchQuery}
            />
          );
        } else if (header?.isCondition) {
          return row[header.field] ? "Yes" : "No";
        } else {
          return getField(row, header?.field, header?.type);
        }
      }
    }
  };

  function getField(row, field, typeValue) {
    if (field) {
      let splitValue = field.split(".");
      let value = "";
      if (splitValue.length >= 2) {
        if (splitValue.length == 3) {
          value = row[splitValue[0]][splitValue[1]][splitValue[2]];
        } else {
          value = row[splitValue[0]] ? row[splitValue[0]][splitValue[1]] : "";
        }
      } else {
        value = row[splitValue[0]];
      }

      if (typeValue === "date-time") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="DD-MMM-YYYY hh:mm a" />
          )) ||
          ""
        );
      } else if (typeValue === "date") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="DD-MMM-YYYY" />
          )) ||
          ""
        );
      } else if (typeValue === "time") {
        return (
          (value !== "" && value !== "None" && (
            <NuDate value={value} format="hh:mm a" />
          )) ||
          ""
        );
      } else if (typeValue === "amount") {
        return (
          (
            <NuAmount
              currency={row["vendorCurrency"] || row["currency"]}
              value={value}
            />
          ) || ""
        );
      } else if (Array.isArray(value)) {
        return value.join(", ");
      } else if (typeValue === "email") {
        return (
          <p className=" max-w-[130px] text-center overflow-x-hidden truncate">
            {value}
          </p>
        );
      } else {
        return value;
      }
    }
  }

  function getHeaderId(row) {
    return row[getById] || 0;
  }

  const [DateNewShow, setDateNewShow] = useState(false);
  const [value, setValue] = useState([]);
  const [yprno, setYprno] = useState("");

  const handleDateIconClick = (value) => {
    setDateNewShow(!DateNewShow);
    setValue(value?.tracker)
    setYprno(value?.ref)
  };

  const closedate = () => {
    setValue([]);
    setYprno("");
    setDateNewShow(false);
  };

//   const date1 = value[0]?.date;
// const date2 = value[1]?.date;
// const date = value[2]?.date;


// const daysDifference = date1 && date2 ? moment(date2).diff(moment(date1), "days") : null;


// const displayDays = daysDifference === 0 ? 1 : daysDifference;

const dates = value.map(item => item?.date).filter(Boolean);


const daysDifferences = dates.map((date, index) => {
  if (index === 0) return null; 
  const diff = moment(date).diff(moment(dates[index - 1]), "days");
  return diff === 0 ? 1 : diff; 
});

const DateBetween = (dateone,datetwo)=>{
  const diff = moment(datetwo).diff(moment(dateone), "days");
  return diff === 0 ? 1 : diff+1; 
}


  return (
    <div className="w-full h-[90%] overflow-hidden">
      <div className="w-full relative max-h-[calc(100vh-145px)] overflow-y-auto">
        <div className=" w-full sticky top-0">
          <table className="w-full" dir={i18n.language == "ar" ? "rtl" : ""}>
            <thead className=" w-full">
              <tr className="text-xs   text-[#4F4768] bg-white z-[9999]">
                {(hasMultiEdit || hasMultiDelete) && (
                  <th className="w-[0.3%] pl-2 bg-white z-[9999]">
                    <input
                      type="checkbox"
                      onChange={(e) => selectMultiple(e)}
                      checked={
                        fullList.length > 0 &&
                        listofNumbers.length === fullList.length
                      }
                    />
                  </th>
                )}
                {headers.map((header, index) =>
                  header.mult === true ? (
                    <th
                      key={index}
                      className="py-2  truncate text-[12px] 2xl:text-sm font-medium bg-white z-50"
                      style={{
                        width: header.width,
                        textAlign: header.rowAlign,
                      }}
                    >
                      <span className="w-1 h-1 bg-red-600 rounded-full inline-block "></span>{" "}
                      {t(header.title) || ""}{" "}
                      <span className="py-2 truncate text-[12px] 2xl:text-sm font-thin text-gray-700">
                        /
                      </span>
                      <span className="w-1 h-1 bg-green-600 rounded-full inline-block "></span>{" "}
                      <span className="py-2 pr-[1px] truncate text-[12px] 2xl:text-sm font-medium ">
                        {t(header.title2) || ""}
                      </span>
                    </th>
                  ) : (
                    <th
                      key={index}
                      className="py-2  truncate text-[12px] 2xl:text-sm font-medium bg-white z-50"
                      style={{
                        width: header.width,
                        textAlign: header.rowAlign,
                      }}
                    >
                      {t(header.title) || ""}
                    </th>
                  )
                )}
              </tr>
            </thead>
          </table>
          <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2]  mx-auto"></div>
        </div>
        <div className={` h-auto z-10 pb-4 mb-16`}>
          <table className="w-full" dir={i18n.language == "ar" ? "rtl" : ""}>
            <tbody>
              {dataLoading == true ? (
                <>
                  <div className=" w-full h-[50vh] flex justify-center items-center">
                    <div className=" flex flex-col justify-center items-center">
                      <div className="flex flex-row gap-2">
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce" />
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.3s]" />
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.5s]" />
                        <div className="w-3 h-3 rounded-full bg-baseColor-quartary animate-bounce [animation-delay:-.7s]" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {data.length > 0 ? (
                    <>
                      {data.map((row, rowIndex) => (
                        <tr
                          className=" hover:text-baseColor-primary hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]  text-[#606279]"
                          key={rowIndex}
                        >
                          {(hasMultiEdit || hasMultiDelete) && (
                            <td className="w-[0.3%] pl-2 ">
                              <input
                                type="checkbox"
                                checked={listofNumbers.some(
                                  (id) => id === getHeaderId(row)
                                )}
                                onChange={() => selectOne(getHeaderId(row))}
                              />
                            </td>
                          )}
                          {dateShow && (
                            <td
                              className="w-[0.1%] cursor-pointer pb-0.5 pl-2 "
                              onClick={()=>handleDateIconClick(row)}
                            >
                              <Date />
                            </td>
                          )}

                          {headers.map((header, colIndex) => {
                            return (
                              <td
                                className="py-3   text-xs text-center overflow-x-hidden"
                                key={colIndex}
                                style={{
                                  width: header.width,
                                  textAlign: header.textAlign,
                                }}
                              >
                                <div className=" w-full flex justify-center">
                                  {RenderCell({ header, row })}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className=" w-full h-[50vh] flex justify-center items-center">
                        <div className=" flex flex-col justify-center items-center">
                          <FileIcon />
                          <p className=" font-medium text-sm 2xl:text-base text-baseColor-secondary my-2">
                            No Data found
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {DateNewShow && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[85%] max-w-[95%] min-h-[55vh] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-center font-semibold mb-4 text-sm 2xl:text-base">Order Flow <span className=" text-gray-700 text-xs 2xl:text-sm">({yprno})</span></h2>
            <div className=" w-full grid grid-cols-12 gap-0">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className=" border-b-[1.5px] border-x-0 h-28  relative"></div>
              <div className=" border-[1.5px] border-x-0 h-28 relative">
                <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                {
                    (value[0]?.order == 1 && value[0]?.status == "PR Created") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[0]?.order == 1 && value[0]?.status == "PR Created")==true?" text-black":" text-slate-500"} absolute -left-6 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">YPR Created</p>
                <p className="text-[10px]  2xl:text-xs">{value[0]?.order == 1 && value[0]?.status == "PR Created" &&  <NuDate value={value[0]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>

              <div className=" border-[1.5px] border-x-0 h-28 relative"></div>
              
              <div className=" border-[1.5px] border-x-0 h-28 relative">
                <div>
              <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-5 2xl:-top-[22px] -left-20 2xl:-left-24">&#129170;</span>
              {(value[1]?.order == 2 && value[1]?.status == "RFQ Floated") &&
              <p className="absolute pt-0.5 2xl:pt-1 text-slate-700 -top-0 2xl:-top-[2px] -left-24 2xl:-left-28 text-[10px]  2xl:text-xs">
              {DateBetween(value[0]?.date,value[1]?.date)} {DateBetween(value[0]?.date,value[1]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>
              <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                  {
                    (value[1]?.order == 2 && value[1]?.status == "RFQ Floated") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[1]?.order == 2 && value[1]?.status == "RFQ Floated")==true?" text-black":" text-slate-500"} absolute -left-6 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px]  2xl:text-xs">RFQ Floated</p>
                <p className="text-[10px]  2xl:text-xs">{value[1]?.order == 2 && value[1]?.status == "RFQ Floated" &&  <NuDate value={value[1]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div className=" border-[1.5px] border-x-0 h-28 relative"></div>
              
              <div className=" border-[1.5px] border-x-0 h-28 relative">
              <div>
              <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-5 2xl:-top-[22px] -left-20 2xl:-left-24">&#129170;</span>
              { (value[2]?.order == 3 && value[2]?.status == "Supplier Price Received") &&
              <p className="absolute pt-0.5 2xl:pt-1 text-slate-700 -top-0 2xl:-top-[2px] -left-24 2xl:-left-28 text-[10px]  2xl:text-xs">
       
       {DateBetween(value[0]?.date,value[2]?.date)} {DateBetween(value[0]?.date,value[2]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>        <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                {
                    (value[2]?.order == 3 && value[2]?.status == "Supplier Price Received") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[2]?.order == 3 && value[2]?.status == "Supplier Price Received")==true?" text-black":" text-slate-500"} absolute -left-12 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px]  2xl:text-xs">Supplier Price Received</p>
                <p className="text-[10px]  2xl:text-xs">{value[2]?.order == 3 && value[2]?.status == "Supplier Price Received" &&  <NuDate value={value[2]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>

              <div className=" border-[1.5px] border-x-0 h-28 relative"></div>
              <div className=" border-[1.5px] border-x-0 h-28 relative">
              <div>
              <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-5 2xl:-top-[22px] -left-20 2xl:-left-24">&#129170;</span>
              {(value[3]?.order == 4 && value[3]?.status == "Customer Quote Approved") &&
              <p className="absolute pt-0.5 2xl:pt-1 text-slate-700 -top-0 2xl:-top-[2px] -left-24 2xl:-left-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[3]?.date)} {DateBetween(value[0]?.date,value[3]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>       <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                {
                    (value[3]?.order == 4 && value[3]?.status == "Customer Quote Approved") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[3]?.order == 4 && value[3]?.status == "Customer Quote Approved")==true?" text-black":" text-slate-500"} absolute -left-14 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px]  2xl:text-xs">Customer Quote Approved</p>
                <p className="text-[10px]  2xl:text-xs">{value[3]?.order == 4 && value[3]?.status == "Customer Quote Approved" &&  <NuDate value={value[3]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              
              <div className=" border-[1.5px] border-x-0 h-28 relative"></div>
              
              <div className=" border-[1.5px] border-x-0 h-28 relative">
              <div>
              <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-5 2xl:-top-[22px] -left-20 2xl:-left-24">&#129170;</span>
              {(value[4]?.order == 5 && value[4]?.status == "Quote Submitted to Customer") && (
              <p className="absolute pt-0.5 2xl:pt-1 text-slate-700  -top-0 2xl:-top-[2px] -left-24 2xl:-left-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[4]?.date)} {DateBetween(value[0]?.date,value[4]?.date) > 1 ? "Days":"Day"}
    </p>)}
              </div>        <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                {
                    (value[4]?.order == 5 && value[4]?.status == "Quote Submitted to Customer") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[4]?.order == 5 && value[4]?.status == "Quote Submitted to Customer")==true?" text-black":" text-slate-500"} absolute -left-14 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">Quote Submitted to Customer</p>
                <p className="text-[10px]  2xl:text-xs">{value[4]?.order == 5 && value[4]?.status == "Quote Submitted to Customer" &&  <NuDate value={value[4]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div className=" border-[1.5px] border-l-0 h-28 rounded-e-full"></div>
              <div className=" border-[1.5px] border-r-0 h-28 rounded-s-full"></div>
              <div className=" border-b-[1.5px] h-28 relative">
                <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                <div>
                  <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-3 2xl:-top-3 -right-20 2xl:-right-24">&#129168;</span>
               {(value[9]?.order == 10 && value[9]?.status == "Order Shipped")  &&
                  <p className="absolute pt-4 2xl:pt-3 text-slate-700  -top-1 2xl:-top-0 -right-24 2xl:-right-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[9]?.date)} {DateBetween(value[0]?.date,value[9]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>  
                {
                    (value[9]?.order == 10 && value[9]?.status == "Order Shipped") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[9]?.order == 10 && value[9]?.status == "Order Shipped")==true?" text-black":" text-slate-500"} absolute -left-5 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">Order Shipped</p>
                <p className="text-[10px]  2xl:text-xs">{value[9]?.order == 10 && value[9]?.status == "Order Shipped" &&  <NuDate value={value[9]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div className=" border-b-[1.5px] h-28"></div>
              <div className=" border-b-[1.5px] h-28 relative">
               <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
               <div>
                  <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-3 2xl:-top-3 -right-20 2xl:-right-24">&#129168;</span>
               {  (value[8]?.order == 9 && value[8]?.status == "PO placed with Supplier")  &&
                  <p className="absolute pt-4 2xl:pt-3 text-slate-700  -top-1 2xl:-top-0 -right-24 2xl:-right-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[8]?.date)} {DateBetween(value[0]?.date,value[8]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>                  {
                    (value[8]?.order == 9 && value[8]?.status == "PO placed with Supplier") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[8]?.order == 9 && value[8]?.status == "PO placed with Supplier")==true?" text-black":" text-slate-500"} absolute -left-11 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">PO placed with Supplier</p>
                <p className="text-[10px]  2xl:text-xs">{value[8]?.order == 9 && value[8]?.status == "PO placed with Supplier" &&  <NuDate value={value[8]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div className=" border-b-[1.5px] h-28"></div>
              <div className=" border-b-[1.5px] h-28 relative">
                <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                <div>
                  <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-3 2xl:-top-3 -right-20 2xl:-right-24">&#129168;</span>
               { (value[7]?.order == 8 && value[7]?.status == "PO to Supplier Approved")&&
                  <p className="absolute pt-4 2xl:pt-3 text-slate-700  -top-1 2xl:-top-0 -right-24 2xl:-right-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[7]?.date)} {DateBetween(value[0]?.date,value[7]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>                  {
                    (value[7]?.order == 8 && value[7]?.status == "PO to Supplier Approved") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[7]?.order == 8 && value[7]?.status == "PO to Supplier Approved")==true?" text-black":" text-slate-500"} absolute -left-11 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">Po to Supplier Approved</p>
                <p className="text-[10px]  2xl:text-xs">{value[7]?.order == 8 && value[7]?.status == "PO to Supplier Approved" &&  <NuDate value={value[7]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div></div>
              <div className=" h-28 relative">
                <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                <div>
                  <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-3 2xl:-top-3 -right-20 2xl:-right-24">&#129168;</span>
               {(value[6]?.order == 7 && value[6]?.status == "Sales Order Released") &&
                  <p className="absolute pt-4 2xl:pt-3 text-slate-700  -top-1 2xl:-top-0 -right-24 2xl:-right-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[6]?.date)} {DateBetween(value[0]?.date,value[6]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>                  {
                    (value[6]?.order == 7 && value[6]?.status == "Sales Order Released") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[6]?.order == 7 && value[6]?.status == "Sales Order Released")==true?" text-black":" text-slate-500"} absolute -left-10 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">Sales Order Released</p>
                <p className="text-[10px]  2xl:text-xs">{value[6]?.order == 7 && value[6]?.status == "Sales Order Released" &&  <NuDate value={value[6]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div></div>
              <div className=" h-28 relative">
                <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                <div>
                  <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-3 2xl:-top-3 -right-20 2xl:-right-24">&#129168;</span>
               { (value[5]?.order == 6 && (value[5]?.status == "PO Received from customer"||value[5]?.status == "Lost the Order")) &&
                  <p className="absolute pt-4 2xl:pt-3 text-slate-700  -top-1 2xl:-top-0 -right-24 2xl:-right-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[5]?.date)} {DateBetween(value[0]?.date,value[5]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>            {
                    (value[5]?.order == 6 && (value[5]?.status == "PO Received from customer"||value[5]?.status == "Lost the Order")) !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[5]?.order == 6 && (value[5]?.status == "PO Received from customer"||value[5]?.status == "Lost the Order"))==true?" text-black":" text-slate-500"} absolute -left-16 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">{value[5]?.status == "PO Received from customer" ? "PO Received from customer":value[5]?.status == "Lost the Order" ?"":"PO Received from customer / Lost the Order"}</p>
                <p className="text-[10px]  2xl:text-xs">{value[5]?.order == 6 && (value[5]?.status == "PO Received from customer"||value[5]?.status == "Lost the Order") &&  <NuDate value={value[5]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="relative">
              <div>
              <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-5 2xl:-top-[22px] -left-20 2xl:-left-24">&#129170;</span>
              {(value[10]?.order == 11 && value[10]?.status == "Order Under Customers clearance") &&
              <p className="absolute pt-0.5 2xl:pt-1 text-slate-700 -top-0 2xl:-top-[2px] -left-24 2xl:-left-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[10]?.date)} {DateBetween(value[0]?.date,value[10]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>           <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                {
                    (value[10]?.order == 11 && value[10]?.status == "Order Under Customers clearance") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[10]?.order == 11 && value[10]?.status == "Order Under Customers clearance")==true?" text-black":" text-slate-500"} absolute -left-12 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px]  2xl:text-xs">Order Under Customers Clearance</p>
                <p className="text-[10px]  2xl:text-xs">{value[10]?.order == 11 && value[10]?.status == "Order Under Customers clearance" &&  <NuDate value={value[10]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div></div>
              <div className="relative">
              <div>
              <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-5 2xl:-top-[22px] -left-20 2xl:-left-24">&#129170;</span>
              { (value[11]?.order == 12 && value[11]?.status == "Order Received at Warehouse") &&
              <p className="absolute pt-0.5 2xl:pt-1 text-slate-700 -top-0 2xl:-top-[2px] -left-24 2xl:-left-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[11]?.date)} {DateBetween(value[0]?.date,value[11]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>       <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                {
                    (value[11]?.order == 12 && value[11]?.status == "Order Received at Warehouse") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[11]?.order == 12 && value[11]?.status == "Order Received at Warehouse")==true?" text-black":" text-slate-500"} absolute -left-14 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">Order Received at Warehouse</p>
                <p className="text-[10px]  2xl:text-xs">{value[11]?.order == 12 && value[11]?.status == "Order Received at Warehouse" &&  <NuDate value={value[11]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
              <div></div>
              <div className="relative">
              <div>
              <span className=" text-gray-400 text-3xl 2xl:text-4xl absolute -top-5 2xl:-top-[22px] -left-20 2xl:-left-24">&#129170;</span>
              {(value[12]?.order == 13 && value[12]?.status == "Delivered to Customer") &&
              <p className="absolute pt-0.5 2xl:pt-1 text-slate-700 -top-0 2xl:-top-[2px] -left-24 2xl:-left-28 text-[10px]  2xl:text-xs">
       {DateBetween(value[0]?.date,value[12]?.date)} {DateBetween(value[0]?.date,value[12]?.date) > 1 ? "Days":"Day"}
    </p>}
              </div>      <div className="absolute -top-2 2xl:-top-2.5 text-slate-400 left-0 w-4 h-4 2xl:w-5 2xl:h-5 bg-green-500 rounded-full flex justify-center items-center">
                {
                    (value[12]?.order == 13 && value[12]?.status == "Delivered to Customer") !=true && <div className=" w-4 h-4 2xl:w-5 2xl:h-5 bg-slate-400 rounded-full "></div>
                  }
                </div>
                <div className={`${(value[12]?.order == 13 && value[12]?.status == "Delivered to Customer")==true?" text-black":" text-slate-500"} absolute -left-11 top-3 w-[170px] 2xl:w-[200px]`}>
                <p className=" text-[10px] 2xl:text-xs">Delivered to Customer</p>
                <p className="text-[10px]  2xl:text-xs">{value[12]?.order == 13 && value[12]?.status == "Delivered to Customer" &&  <NuDate value={value[12]?.date} format="DD-MMM-YYYY"/> }</p>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                className={`m-3 px-4 py-[6px] cursor-pointer text-xs 2xl:text-sm  text-textColor-main bg-[#E6F2F2] hover:bg-gray-300
      rounded-md flex items-center`}
                style={{ border: "none" }}
                onClick={closedate}
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Example usage
const CustomDataTable = ({
  fetcher,
  data,
  availableHeaders,
  defaultHeader,
  total,
  pageIndex,
  setPageIndex,
  dataCount,
  setDataCount,
  height = "h-[calc(100vh-145px)]",
  bodyHeight = "max-h-[calc(100vh-280px)]",
  title = "",
  storageName = "",
  hasMultiEdit = false,
  hasMultiDelete = false,
  selectMultipleFunction = () => {},
  selectOneFunction = () => {},
  listofNumbers = [],
  setShowEdit = () => {},
  showEdit = false,
  setShowDelete = () => {},
  showDelete = false,
  fullList = [],
  getById = "",
  showFieldFalse,
  editAccess = "",
  deteleAccess = "",
  dataLoading = false,
  searchQuery = "",
  dateShow = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  // Retrieve headers from local storage or set default headers
  const storedHeaders = JSON.parse(localStorage.getItem(storageName));
  const defaultHeaders = storedHeaders || defaultHeader || availableHeaders;

  // State to track table headers and remaining headers
  const [headersState, setHeadersState] = useState(defaultHeaders);

  useEffect(() => {
    localStorage.setItem(storageName, JSON.stringify(headersState));
  }, [headersState]);

  const toggleHeader = (header) => {
    if (headersState.some((h) => h.field === header.field)) {
      // Remove the header from headersState
      setHeadersState((prevHeaders) =>
        prevHeaders.filter((h) => h.field !== header.field)
      );
    } else {
      // Find the index of the toggled header in availableHeaders
      const headerIndex = availableHeaders.findIndex(
        (h) => h.field === header.field
      );
      // Insert the header into headersState at the same index
      setHeadersState((prevHeaders) => [
        ...prevHeaders.slice(0, headerIndex),
        header,
        ...prevHeaders.slice(headerIndex),
      ]);
    }
  };

  return (
    <div className={`w-full ${height} rounded-md bg-white p-3 mt-4 relative`}>
      <div className=" flex justify-between items-center ">
        {/* <TextMediumSM content={title} color="text-[#4F4768]"/>  */}
        <p className="font-medium text-xs 2xl:text-sm text-[#4F4768]">
          {title} {data.length > 0 && `: ${total}`}
        </p>
        <div className=" flex gap-3 items-center">
          {hasMultiEdit && listofNumbers.length > 0 && (
            <button
              className=" bg-[#048178] px-3 py-[3px] text-white text-xs font-medium rounded-md "
              onClick={() => setShowEdit(!showEdit)}
            >
              {title === "Supplier" ? "Edit Access" : "Multi Edit"}
            </button>
          )}
          {hasMultiDelete && listofNumbers.length > 0 && (
            <button
              className=" bg-[#048178] px-3 py-[3px] text-white text-xs font-medium rounded-md "
              onClick={() => setShowDelete(!showDelete)}
            >
              Delete
            </button>
          )}
        </div>
        {showFieldFalse == false ? null : (
          <>
            {GetRole() && (
              <div
                className=" rounded-lg  bg-slate-300  px-3 py-1 text-xs  cursor-pointer text-[#4F4768]"
                onClick={() => setIsModalOpen(true)}
              >
                {t("Show Feilds")}
              </div>
            )}
          </>
        )}
      </div>

      <DynamicTable
        fetcher={fetcher}
        data={data}
        headers={headersState}
        bodyHeight={bodyHeight}
        hasMultiEdit={hasMultiEdit}
        hasMultiDelete={hasMultiDelete}
        listofNumbers={listofNumbers}
        selectOne={selectOneFunction}
        selectMultiple={selectMultipleFunction}
        fullList={fullList}
        getById={getById}
        EditAccess={editAccess}
        DeteleAccess={deteleAccess}
        dataLoading={dataLoading}
        total={total}
        pageIndex={pageIndex}
        searchQuery={searchQuery}
        dateShow={dateShow}
      />
      <div className=" w-full h-[40px] text-[#4f4768]">
        <Pagination
          total={total}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Table Headers"
        content={
          <div>
            {availableHeaders.map((header, index) => (
              <div
                key={index}
                className=" flex justify-start items-center gap-3 py-1"
                dir={i18n.language == "ar" ? "rtl" : ""}
              >
                <input
                  type="checkbox"
                  id={`header-${index}`}
                  checked={headersState.some((h) => h.field === header.field)}
                  onChange={() => toggleHeader(header)}
                />

                <label className=" font-medium" htmlFor={`header-${index}`}>
                  {t(header.title)}
                </label>
              </div>
            ))}
          </div>
        }
        handleClick={() => setIsModalOpen(false)}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default CustomDataTable;
