import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { GetDashboardRole } from "../../../../../Components/Utils/roles";
import Breadcrumb from "../../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../../Components/Utils/Buttons";


function ProcurementReportLayout() {
    const location = useLocation();
    let pathFile = location.pathname;
    const [value,setValue] = useState("")


    useEffect(()=>{
        if(pathFile === "/reports/procurement/cost-saving-analysis"){
            setValue("Cost Saving Analysis")
        }else if(pathFile === "/reports/procurement/purchase-order"){
          setValue("Purchase Order")
      }
    },[location])

  return (
    <div className=" w-full h-full  px-4 py-2 overflow-hidden">
      <div className=" w-full flex justify-between items-center">
      <Breadcrumb items={[
        {
          name: "Report",
          link: "/reports",
        },
        {
            name: "Procurement",
        },
        {
            name: value,
        }
      ]} />

      <BackButton/>
      </div>

      <div className="w-[100%] h-[calc(100vh-90px)] mt-1">
        <div className="w-[100%] h-[calc(100vh-130px)] overflow-y-auto bg-white rounded-lg px-3 pb-1">
            <Outlet/>
        </div>
      </div>
    </div>
  );
}

export default ProcurementReportLayout;