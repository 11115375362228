import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, {
  NuDataTime,
  NuInput,
  NuSelect,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const EditAppoinment = () => {
  const { value: Industry } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Industry_Appoinments"
  );
  const { value: VisitPurpose } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "VisitPurpose_Appoinments"
  );
  const { value: Status, getData: refStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_Appoinments"
  );
  const { data: customers } = UseDropDown("/customers/drop-down");
  const { data: lead } = UseDropDown("/leads/drop-down");
  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [email, setEmail] = useState("");
  // const [loading, setLoading] = useState(false);

  const [createNew, setCreateNew] = useState({
    industry: data?.industry || "",
    visitPurpose: data?.visitPurpose || "",
    title: data?.title || "",
    reminder: data?.reminder || "",
    dateTime: data?.dateTime || "",
    comments: data?.comments || "",
    holderType: data?.holderType || "",
    holderId: data?.holderId || "",
    status: data?.status || "",
    email: data?.email || "",
    Contact_Person: data?.Contact_Person || "",
    Company_Name: data?.Company_Name || "",
    Priority: data?.Priority || "",
    Location: data?.Location || "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      title: yup.string().required("Title is required!"),
      industry: yup.string().required("Industry is required!"),
      visitPurpose: yup.string().required("Visit Purpose is required!"),
    }),

    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      editData(
        `/appointments/edit-appointment/${data.appointmentId}`,
        value,
        resetForm,
        from
      );
      // setLoading(false)
    },
  });

  const getData = async (holderId, holderType) => {
    try {
      const endpoint =
        holderType === "Customer"
          ? `/customers/${holderId}`
          : `/leads/${holderId}`;
      const response = await axiosInstance.get(endpoint);
      console.log("Fetched Data:", response.data?.data);
      if (response.data?.data) {
        const fetchedEmail = response.data.data.email || ""; // Default to empty string if no email
        setEmail(fetchedEmail);
        formik.setFieldValue("email", fetchedEmail); // Update formik value
      } else {
        setEmail("");
        formik.setFieldValue("email", ""); // Set to empty if no data
      }
    } catch (err) {
      console.error("Error fetching data", err);
      setEmail("");
      formik.setFieldValue("email", ""); // Set to empty in case of error
    }
  };

  useEffect(() => {
    if (formik.values.holderId && formik.values.holderType) {
      console.log(
        "holderId:",
        formik.values.holderId,
        "holderType:",
        formik.values.holderType
      );
      getData(formik.values.holderId, formik.values.holderType);
    } else {
      setEmail("");
      formik.setFieldValue("email", ""); // Clear email when holderType or holderId changes
    }
  }, [formik.values.holderId, formik.values.holderType]);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Marketing",
              link: "/marketing/appointments",
            },
            {
              name: "Appointment",
              link: "/marketing/appointments",
            },
            {
              name: "Edit Appointment",
            },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              type="text"
              label="Title"
              placeholder="title"
              formik={formik}
              name="title"
              width="w-full md:w-1/2 lg:w-2/3"
              isRequired={true}
            />
             <FormikSelectTwo
              type="text"
              label="Contact type"
              placeholder="Choose"
              Options={[
                { value: "New", label: "New" },
                { value: "Lead", label: "Lead" },
                { value: "Customer", label: "Customer" },
              ]}
              formik={formik}
              name="holderType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {formik.values.holderType === "Customer" && (
              <FormikSelectTwo
                type="text"
                label="Holder"
                placeholder="Choose"
                Options={customers}
                formik={formik}
                name="holderId"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )}
            {formik.values.holderType === "Lead" && (
              <FormikSelectTwo
                type="text"
                label="Holder "
                placeholder="Choose"
                Options={lead}
                formik={formik}
                name="holderId"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )}
            {formik.values.holderId && (
              <NuInput
                type="email"
                label="Email"
                placeholder="Email"
                formik={formik}
                name="email"
                value={formik.values.email}
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              />
            )}
            <NuInput
              type="text"
              label="Contact Person"
              placeholder="Contact Person"
              formik={formik}
              name="Contact_Person"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="Company_Name"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              type="text"
              label="Location"
              placeholder="Location"
              formik={formik}
              name="Location"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <FormikSelectTwo
              type="text"
              label="Industry"
              placeholder="Choose"
              Options={Industry}
              formik={formik}
              name="industry"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <FormikSelectTwo
              type="text"
              label="Visit Purpose"
              placeholder="Choose"
              Options={VisitPurpose}
              formik={formik}
              name="visitPurpose"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <NuDataTime
              label="Appoinment Date "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dateTime"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Priority"
              placeholder="Choose"
              Options={[
                { value: "High", label: "High" },
                { value: "Low", label: "Low" },
                { value: "Medium", label: "Medium" },
              ]}
              formik={formik}
              name="Priority"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            <NuDataTime
              label="Reminder"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="reminder"
              width="w-full md:w-1/2 lg:w-1/3"
            />

           

            <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refStatus}
              displayProperty={"Status"}
              propertyName={"Status_Appoinments"}
            />

            {/* <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Choose"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
              new_width="lg:w-2/3"
            />
            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAppoinment;
