import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewMore from "../Icons/ViewMore";
import { useDeleteData } from "../Hooks/useDeleteData";
import ModalContainer from "../Others/ModalContainer";
import EditIcon from "../Icons/Edit";
import DeleteIcon from "../Icons/Delete";
import CommentsIcon from "../Icons/comments";
import ViewIcon from "../Icons/ViewIcon";
import Chat from "../Icons/Chat";
import { GetAccess, GetRole } from "./roles";
import Email from "../Icons/Email";
import EmailModal from "./EmailModel";
import ModalEmail from "../Others/ModalForEmail";
import { FaCheckCircle, FaClipboardCheck, FaEye, FaHourglassHalf, FaRegFileAlt, FaRegThumbsUp, FaTimesCircle } from "react-icons/fa";

const MoreOptions = ({
  data,
  fetcher,
  id,
  removevalue,
  url,
  moduleName,
  from,
  viewLink,
  editLink,
  EmailRequired,
  emailEnable,
  isEmailSent,
  EditAccess = "",
  DeteleAccess = "",
  emailType,
  adminPermmision,
  curentPage,
  searchQuery
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { handle } = useDeleteData(fetcher);
  const handleDelete = async () => handle(url + `${id}`, setIsModalOpen);
  let Edit = editLink ? true : null;
  let Comment = editLink ? false : null;

  let EditCondition = editLink?.includes("{id}") ? true : false;
  let editableLink = EditCondition ? editLink.replace("{id}", `${id}`) : "";

  let Delete = true;
  

  const getStatusIcon = () => {
    if (data?.approvalStatus?.toLowerCase() === "approved") {
      return <FaCheckCircle className="text-green-500" title="Approved" />;
    }
    if (data?.approvalStatus?.toLowerCase() === "pending") {
      return <FaHourglassHalf className="text-yellow-500" title="Pending" />;
    }
    if (data?.approvalStatus?.toLowerCase() === "rejected" || data?.approvalStatus?.toLowerCase() === "declined") {
      return <FaTimesCircle className="text-red-500" title={data?.approvalStatus?.toLowerCase() === "rejected" || data?.approvalStatus?.toLowerCase() === "declined"} />;
    }
    if (data?.approvalStatus?.toLowerCase() === "verified") {
      return <FaClipboardCheck className="text-purple-500 " title="Verified" />;
    }
    if (data?.approvalStatus?.toLowerCase() === "reviewed") {
      return <FaRegFileAlt className="text-indigo-500" title="Reviewed" />;
    }
    
    
    
    
    return null;
  };

  console.log("EDIT ",Edit,moduleName);
  console.log("EDIT ACCESS",GetAccess(EditAccess));
  console.log("DELETE ACCESS",GetAccess(DeteleAccess));
  

  return (
    <>
      <div className=" w-full flex  justify-start items-center">
        <div className=" w-full flex justify-start ">
          
        {moduleName !== "Sales-Orders" && (
  <div className="w-[20%] overflow-hidden flex justify-center items-center">
    {getStatusIcon()}
  </div>
)}

          {EmailRequired && !isEmailSent && !data?.purchaser && emailEnable && (
            <div
              className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <Email height={"3"} width={"3"} color="#BF2EF0" />
            </div>
          )}
          
          <div className=" w-[25%] overflow-hidden flex justify-center items-center">
            <Link
              className=" text-black hover:text-black border-none"
              to={viewLink + `${id}`}
              state={{ 
                data: data,
                pageIndex:curentPage,
                searchQuery: searchQuery!==""?searchQuery:searchQuery
               }}
            >
              <ViewIcon color="#048178" />
            </Link>
          </div>
          {Edit && (
            <>
              {data?.approvalStatus?.toLowerCase() == "verified" ||
              data?.approvalStatus?.toLowerCase() == "approved" ? (
                <>
                {
                  moduleName.toLowerCase() == "purchase order"||moduleName.toLowerCase() == "ypr" ?
                  (
                    <>
                    {GetAccess(EditAccess) && (
                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={EditCondition ? editableLink : editLink + `${id}`}
                        state={{ data: data, from: from }}
                      >
                        <EditIcon color="skyblue" width={"12"} height={"12"} />
                      </Link>
                    </div>
                  )}
                    </>
                  ):(
                    <>
                  {GetRole() && (
                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={EditCondition ? editableLink : editLink + `${id}`}
                        state={{ data: data, from: from }}
                      >
                        <EditIcon color="skyblue" width={"12"} height={"12"} />
                      </Link>
                    </div>
                  )}
                    </>
                  )
                }
                </>
              ) : (
                <>
                  {GetAccess(EditAccess) && (
                    <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
                      <Link
                        className=" text-black hover:text-black border-none"
                        to={EditCondition ? editableLink : editLink + `${id}`}
                        state={{ data: data, from: from }}
                      >
                        <EditIcon color="skyblue" width={"12"} height={"12"} />
                      </Link>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {Delete && (
            <>
              {data?.approvalStatus?.toLowerCase() == "verified" ||
              data?.approvalStatus?.toLowerCase() == "approved" ? (
                <>
                  {GetRole() && (
                    <div
                      className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {GetAccess(DeteleAccess) && (
                    <div
                      className=" w-[25%] overflow-hidden flex justify-center items-center cursor-pointer "
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {Comment && (
            <div className=" w-[25%] overflow-hidden flex justify-center items-center  cursor-pointer ">
              <Chat width={"11"} height={"11"} />
            </div>
          )}
        </div>
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Warning"
        content={`Are you sure, ${removevalue} will removed from ${moduleName}!`}
        handleClick={() => handleDelete()}
        closeModal={() => setIsModalOpen(false)}
      />
      <ModalEmail
        visiable={modalOpen}
        title=""
        hasContent={true}
        content={
          <>
            <EmailModal
              fetcher={fetcher}
              vendorId={data?.vendor || data?.vendorId}
              id={data?.id}
              url={url}
              idd={id}
              onClose={() => setModalOpen(false)}
              emailType={emailType}
            />
          </>
        }
        // handleClick={() => {}}
        hasButton={false}
        // outSideClick={false}
        closeModal={() => setModalOpen(false)}
      />
    </>
  );
};
export default MoreOptions;
