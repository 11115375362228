import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseFetchReportData = (url, params=null) => {
  const [data, setData] = useState([]);
  const [total,setTotal] = useState(0)
  const [other,setOther] = useState({})

  const [dataLoading, setDataLoading] = useState(true);


  async function getData() {
    setDataLoading(true);
    try {
      const res = await axiosInstance.get(url, { params });
       setData(res?.data?.data || []) 
       setTotal(res?.data?.total || 0)
       setOther(res?.data || {})
       setDataLoading(false);
      
    } catch (error) {
      console.log(error);
      setDataLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [url, JSON.stringify(params)]);

  return { data, getData , dataLoading,total,other };
};

export default UseFetchReportData;